;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"d8ab3611a30a3a2b9e2578f2cda8ccdb1257b10a"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/fem/d8ab3611a30a3a2b9e2578f2cda8ccdb1257b10a";import * as Sentry from '@sentry/nextjs'
import {getEnvironment} from 'sentry.utils'
import {env} from '~/config/env.mjs'
import {isProd} from '~/config/nodeEnv'

Sentry.init({
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,
  debug: false,
  enabled: isProd,
  environment: getEnvironment(),
})
